const {join} = require("path")

module.exports = {
	siteMetadata: {
		siteUrl: `https://www.uprise.co/`
	},
	plugins: [
		`gatsby-plugin-preact`,
		{
			resolve: `gatsby-plugin-netlify`,
			options: {
				headers: {
					"/*": [
						"Content-Security-Policy: frame-ancestors 'self' https://*.storyblok.com/",
						"X-Frame-Options: ALLOW-FROM https://app.storyblok.com/"
					]
				},
				mergeSecurityHeaders: false
			}
		},
		{
			resolve: "gatsby-source-storyblok",
			options: {
				accessToken: "jeAO37JrRyzLfXaUXn16mgtt",
				homeSlug: "home",
				version: process.env.NODE_ENV === "production" ? "published" : "draft"
			}
		},
		// {
		// 	resolve: `gatsby-plugin-sharp`,
		// 	options: {
		// 		defaultQuality: 50
		// 	}
		// },
		`gatsby-plugin-force-trailing-slashes`,
		`gatsby-plugin-react-helmet`,
		`gatsby-plugin-sitemap`,
		`gatsby-plugin-robots-txt`,
		`gatsby-plugin-client-side-redirect`,
		{
			resolve: `gatsby-source-filesystem`,
			options: {
				name: `images`,
				path: `${__dirname}/src/images`
			}
		},
		`gatsby-transformer-sharp`,
		`gatsby-plugin-sharp`,
		{
			resolve: `gatsby-plugin-manifest`,
			options: {
				name: `Uprise.co`,
				short_name: `Uprise`,
				description: `Uprise is a proactive and science-based Employee Assistance Program (EAP) provider in Australia.`,
				lang: `en`,
				start_url: `/`,
				background_color: `#ffffff`,
				theme_color: `#7d60ff`,
				display: `standalone`,
				icon: "src/images/logo.webp"
			}
		},
		{
			resolve: `gatsby-plugin-offline`,
			options: {}
		},
		// {
		// 	resolve: "gatsby-plugin-page-transitions",
		// 	options: {
		// 		transitionTime: 500
		// 	}
		// },
		// {
		// 	resolve: "gatsby-source-google-sheets",
		// 	options: {
		// 		spreadsheetId: "1I5k42Vi1NRi5xLJw_pqc4Jm0AmQJKyHBfhZZpKlIojE",
		// 		worksheetTitle: "Sheet1",
		// 		credentials: {
		// 			type: "service_account",
		// 			project_id: "indigo-listener-254604",
		// 			private_key_id: "bd3c0eb87ab7a86fd5299f4971c08a85c3fbb95e",
		// 			private_key:
		// 				"-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCsFZPp89Wy5akV\nUlpsWz+GV9SLy0VadHgyykELahfKBsw6jQfgLf4tbIh3070L5wjrA5pHGJb04FyG\nD9E2u9Woa2YEMAf9d96xEXnNnN0Uu5IL4OrI8Y6i4ynPKN2HJbqbiEEhkM63L2zl\nLCaT85UhD6qgbCXxSjlNdR0VaCbyLXL2uy7H7ZvHaFcs/zBjMeyiAvolx3aj73bt\nIHrNLA/e54gCG8rbTE4nSWH0Unia+JZxwkZmIkiskAIUUE3Kcv3BFnJGxotDYHpz\nfFEbrogpsW4wGpjVanLu54gGgEzMHBELTrP/8iDukjzkQ3klicxvg4XsU0wWj2hn\ndkmjRa9BAgMBAAECggEAAi9UE2oi+z537JU5EF+SV3A6msem9M2GNAoiDDklph0q\n6zaEoEyyMbFDTG7u81F0qS/4jcT4z/EwMIwvbT4SWe0K6JRD6jSbImE3BEikzJNO\n+O1TKs/pFk2rP4HwudtFbr2KquiG3N/ebnyWfO34lYjYeyjjXJBjSM3TE6040x3h\n8pOdsmDydP1j6oRyMh/+8WUr6K3tX4DJfDToS3Sni4YSs+BHzjFQjyUzRF7n4msA\nQevXqEKeKKCvO11ct3PwaghrgoJYgU8YBPYknFnveZAG9cIqC1vySD+ZPZEu2o2D\nn6k4l0HuGtdcICaUQoIzxDtZF/6tfN7MJf8p7uGXYQKBgQDkpDG7j8Oj5uPl+V0n\n9LV3AUTbC2ZYdojD6cmmig0UVkpoaMvNpE0qc00p31LxAXtH58acVU+vO5MeJ//D\nvYPoAOXQsuzydS687R71F/7LPbNd2wP4RgoBJzfM2Kfo5pOb4c8xxupNgihvSXwY\nMG65mqjor1mYY0EC15OpDXA/oQKBgQDArOk4dVpoWGWg42Bvr8Gh+UY+TJtYXYUB\n1UCeoyUir5oVPZ3PIdPTlIY7AI2UU0+9G6+XTjPG3s11RrVKlfKO49LSJs0LvDuy\nJMv/cKZ+kt4DK0apyFgka80AIUOzWA0ZlvTjnNSHIJ//4lemTqfKVMGYAh6ixisT\n3/3gZpnLoQKBgCcgTf5p0LHonhFJxf5DaxZNWkodvkrfJfJYvJBKSYi0Z5FMdavg\nNPBRwN+sftYNSdp5OEyicLts93UOpT8K3FrEZI99Pv0SvofIECcjDxAao+oow5ol\n6ipeWrcsUDeT1ai0UK+U+Z1/64l3ysuc5iXO6J7A+xUPzx08ZOFmElJhAoGAL1DH\nHSREhWdf3bi4z/SbWol6pIXviC2/IgoailqKHhB9iEXPYTRvboAEzqx+GDS1Wj1Y\nS5HayTWhwvEQSDa2kDyGYzFAHWemSUMcDai+Wkr61lBkVAFtE9SdTTDeDUbMQMJ/\ndP3oex6Z7B7LY33hWqChwhCTMWn03jadg+PuVMECgYAZXpNH8a31yEN7LjiLvvUv\nL/by8/qRsRt2a1ZjO+OEUJEsWzu/plOgeDR/CywQKDnIWG4BNLbibTPEMcJ7EP3a\nLvePRVWhCLJ2XqdzkAtixo4f00yVuGzNDusCeo+/iAebxdGG0ADtLbxbeiPTTeWP\nGXS8ORPV90h9w0Wxwv4XHw==\n-----END PRIVATE KEY-----\n",
		// 			client_email: "sercer@indigo-listener-254604.iam.gserviceaccount.com",
		// 			client_id: "104267935825426305893",
		// 			auth_uri: "https://accounts.google.com/o/oauth2/auth",
		// 			token_uri: "https://oauth2.googleapis.com/token",
		// 			auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
		// 			client_x509_cert_url:
		// 				"https://www.googleapis.com/robot/v1/metadata/x509/sercer%40indigo-listener-254604.iam.gserviceaccount.com"
		// 		}
		// 	}
		// },
		// {
		// 	resolve: "gatsby-source-google-sheets",
		// 	options: {
		// 		spreadsheetId: "1onJJ0XbkaUR_DHcv4XO4ONx6cIpCSrtUsGZU6oLJo0Q",
		// 		worksheetTitle: "Digital Resilience Training",
		// 		credentials: {
		// 			type: "service_account",
		// 			project_id: "indigo-listener-254604",
		// 			private_key_id: "bd3c0eb87ab7a86fd5299f4971c08a85c3fbb95e",
		// 			private_key:
		// 				"-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCsFZPp89Wy5akV\nUlpsWz+GV9SLy0VadHgyykELahfKBsw6jQfgLf4tbIh3070L5wjrA5pHGJb04FyG\nD9E2u9Woa2YEMAf9d96xEXnNnN0Uu5IL4OrI8Y6i4ynPKN2HJbqbiEEhkM63L2zl\nLCaT85UhD6qgbCXxSjlNdR0VaCbyLXL2uy7H7ZvHaFcs/zBjMeyiAvolx3aj73bt\nIHrNLA/e54gCG8rbTE4nSWH0Unia+JZxwkZmIkiskAIUUE3Kcv3BFnJGxotDYHpz\nfFEbrogpsW4wGpjVanLu54gGgEzMHBELTrP/8iDukjzkQ3klicxvg4XsU0wWj2hn\ndkmjRa9BAgMBAAECggEAAi9UE2oi+z537JU5EF+SV3A6msem9M2GNAoiDDklph0q\n6zaEoEyyMbFDTG7u81F0qS/4jcT4z/EwMIwvbT4SWe0K6JRD6jSbImE3BEikzJNO\n+O1TKs/pFk2rP4HwudtFbr2KquiG3N/ebnyWfO34lYjYeyjjXJBjSM3TE6040x3h\n8pOdsmDydP1j6oRyMh/+8WUr6K3tX4DJfDToS3Sni4YSs+BHzjFQjyUzRF7n4msA\nQevXqEKeKKCvO11ct3PwaghrgoJYgU8YBPYknFnveZAG9cIqC1vySD+ZPZEu2o2D\nn6k4l0HuGtdcICaUQoIzxDtZF/6tfN7MJf8p7uGXYQKBgQDkpDG7j8Oj5uPl+V0n\n9LV3AUTbC2ZYdojD6cmmig0UVkpoaMvNpE0qc00p31LxAXtH58acVU+vO5MeJ//D\nvYPoAOXQsuzydS687R71F/7LPbNd2wP4RgoBJzfM2Kfo5pOb4c8xxupNgihvSXwY\nMG65mqjor1mYY0EC15OpDXA/oQKBgQDArOk4dVpoWGWg42Bvr8Gh+UY+TJtYXYUB\n1UCeoyUir5oVPZ3PIdPTlIY7AI2UU0+9G6+XTjPG3s11RrVKlfKO49LSJs0LvDuy\nJMv/cKZ+kt4DK0apyFgka80AIUOzWA0ZlvTjnNSHIJ//4lemTqfKVMGYAh6ixisT\n3/3gZpnLoQKBgCcgTf5p0LHonhFJxf5DaxZNWkodvkrfJfJYvJBKSYi0Z5FMdavg\nNPBRwN+sftYNSdp5OEyicLts93UOpT8K3FrEZI99Pv0SvofIECcjDxAao+oow5ol\n6ipeWrcsUDeT1ai0UK+U+Z1/64l3ysuc5iXO6J7A+xUPzx08ZOFmElJhAoGAL1DH\nHSREhWdf3bi4z/SbWol6pIXviC2/IgoailqKHhB9iEXPYTRvboAEzqx+GDS1Wj1Y\nS5HayTWhwvEQSDa2kDyGYzFAHWemSUMcDai+Wkr61lBkVAFtE9SdTTDeDUbMQMJ/\ndP3oex6Z7B7LY33hWqChwhCTMWn03jadg+PuVMECgYAZXpNH8a31yEN7LjiLvvUv\nL/by8/qRsRt2a1ZjO+OEUJEsWzu/plOgeDR/CywQKDnIWG4BNLbibTPEMcJ7EP3a\nLvePRVWhCLJ2XqdzkAtixo4f00yVuGzNDusCeo+/iAebxdGG0ADtLbxbeiPTTeWP\nGXS8ORPV90h9w0Wxwv4XHw==\n-----END PRIVATE KEY-----\n",
		// 			client_email: "sercer@indigo-listener-254604.iam.gserviceaccount.com",
		// 			client_id: "104267935825426305893",
		// 			auth_uri: "https://accounts.google.com/o/oauth2/auth",
		// 			token_uri: "https://oauth2.googleapis.com/token",
		// 			auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
		// 			client_x509_cert_url:
		// 				"https://www.googleapis.com/robot/v1/metadata/x509/sercer%40indigo-listener-254604.iam.gserviceaccount.com"
		// 		}
		// 	}
		// },
		// {
		// 	resolve: "gatsby-source-google-sheets",
		// 	options: {
		// 		spreadsheetId: "1onJJ0XbkaUR_DHcv4XO4ONx6cIpCSrtUsGZU6oLJo0Q",
		// 		worksheetTitle: "New EAP Pricing",
		// 		credentials: {
		// 			type: "service_account",
		// 			project_id: "indigo-listener-254604",
		// 			private_key_id: "bd3c0eb87ab7a86fd5299f4971c08a85c3fbb95e",
		// 			private_key:
		// 				"-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCsFZPp89Wy5akV\nUlpsWz+GV9SLy0VadHgyykELahfKBsw6jQfgLf4tbIh3070L5wjrA5pHGJb04FyG\nD9E2u9Woa2YEMAf9d96xEXnNnN0Uu5IL4OrI8Y6i4ynPKN2HJbqbiEEhkM63L2zl\nLCaT85UhD6qgbCXxSjlNdR0VaCbyLXL2uy7H7ZvHaFcs/zBjMeyiAvolx3aj73bt\nIHrNLA/e54gCG8rbTE4nSWH0Unia+JZxwkZmIkiskAIUUE3Kcv3BFnJGxotDYHpz\nfFEbrogpsW4wGpjVanLu54gGgEzMHBELTrP/8iDukjzkQ3klicxvg4XsU0wWj2hn\ndkmjRa9BAgMBAAECggEAAi9UE2oi+z537JU5EF+SV3A6msem9M2GNAoiDDklph0q\n6zaEoEyyMbFDTG7u81F0qS/4jcT4z/EwMIwvbT4SWe0K6JRD6jSbImE3BEikzJNO\n+O1TKs/pFk2rP4HwudtFbr2KquiG3N/ebnyWfO34lYjYeyjjXJBjSM3TE6040x3h\n8pOdsmDydP1j6oRyMh/+8WUr6K3tX4DJfDToS3Sni4YSs+BHzjFQjyUzRF7n4msA\nQevXqEKeKKCvO11ct3PwaghrgoJYgU8YBPYknFnveZAG9cIqC1vySD+ZPZEu2o2D\nn6k4l0HuGtdcICaUQoIzxDtZF/6tfN7MJf8p7uGXYQKBgQDkpDG7j8Oj5uPl+V0n\n9LV3AUTbC2ZYdojD6cmmig0UVkpoaMvNpE0qc00p31LxAXtH58acVU+vO5MeJ//D\nvYPoAOXQsuzydS687R71F/7LPbNd2wP4RgoBJzfM2Kfo5pOb4c8xxupNgihvSXwY\nMG65mqjor1mYY0EC15OpDXA/oQKBgQDArOk4dVpoWGWg42Bvr8Gh+UY+TJtYXYUB\n1UCeoyUir5oVPZ3PIdPTlIY7AI2UU0+9G6+XTjPG3s11RrVKlfKO49LSJs0LvDuy\nJMv/cKZ+kt4DK0apyFgka80AIUOzWA0ZlvTjnNSHIJ//4lemTqfKVMGYAh6ixisT\n3/3gZpnLoQKBgCcgTf5p0LHonhFJxf5DaxZNWkodvkrfJfJYvJBKSYi0Z5FMdavg\nNPBRwN+sftYNSdp5OEyicLts93UOpT8K3FrEZI99Pv0SvofIECcjDxAao+oow5ol\n6ipeWrcsUDeT1ai0UK+U+Z1/64l3ysuc5iXO6J7A+xUPzx08ZOFmElJhAoGAL1DH\nHSREhWdf3bi4z/SbWol6pIXviC2/IgoailqKHhB9iEXPYTRvboAEzqx+GDS1Wj1Y\nS5HayTWhwvEQSDa2kDyGYzFAHWemSUMcDai+Wkr61lBkVAFtE9SdTTDeDUbMQMJ/\ndP3oex6Z7B7LY33hWqChwhCTMWn03jadg+PuVMECgYAZXpNH8a31yEN7LjiLvvUv\nL/by8/qRsRt2a1ZjO+OEUJEsWzu/plOgeDR/CywQKDnIWG4BNLbibTPEMcJ7EP3a\nLvePRVWhCLJ2XqdzkAtixo4f00yVuGzNDusCeo+/iAebxdGG0ADtLbxbeiPTTeWP\nGXS8ORPV90h9w0Wxwv4XHw==\n-----END PRIVATE KEY-----\n",
		// 			client_email: "sercer@indigo-listener-254604.iam.gserviceaccount.com",
		// 			client_id: "104267935825426305893",
		// 			auth_uri: "https://accounts.google.com/o/oauth2/auth",
		// 			token_uri: "https://oauth2.googleapis.com/token",
		// 			auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
		// 			client_x509_cert_url:
		// 				"https://www.googleapis.com/robot/v1/metadata/x509/sercer%40indigo-listener-254604.iam.gserviceaccount.com"
		// 		}
		// 	}
		// },
		// {
		// 	resolve: "gatsby-source-google-sheets",
		// 	options: {
		// 		// spreadsheetId: "1bPmpuXyT-S0p26WouzE3qgvyeQYPV3VMptjtPGanJXQ",
		// 		spreadsheetId: "1gxzDGflxUs7PMr025UZThq5j25CkUkbR7KtP3APYmpQ",
		// 		// worksheetTitle: "Fixed Fee",
		// 		worksheetTitle: "Sheet1",
		// 		credentials: {
		// 			type: "service_account",
		// 			project_id: "marketing-site-280107",
		// 			private_key_id: "6cfd02187077dd0a1fdd7f5fbe680a29e74578be",
		// 			private_key:
		// 				"-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDdFPEfQiuWlvrB\n230SNePYKRB78MKnKqiI1aPAEeO0EH1eBYRok/YYAhi1K8BtXM+F/iP8dPeRF3bu\nFGqZgVnqJFHVa6R+eAHbz5Z5Cx2MLsI9RW7UFIVJxJcBpwAYpfhqMx+oZmLwX5G9\nYlVb6O9Nq1uDE7I1f4eNXfAcopmqUXiRBNnFYoz6a2e6OPtNQFiX7MjfplCkhrfI\nid7ie7GsDirq1N33oG88SXyZCf+RDeSUBd3neabncTYC8cQZAJf1zN8bmi+UiW4w\np6GaGxB3UfsLJ0uoOzMh5OukXkhO0jABCKvlToF0dQqFBFJgS/Ht50crs6KL8XPM\nuzpzL4lDAgMBAAECggEABEgT5xaSnj8OuAVmiAr32d5YAk+dRpeoQlfc2qHnNDwI\nFzyy1LGnKhcmlusPfy61UU7t5sVjtSgjP7dDE9D7RpbIeqbYhp9YyUvvigRvctfq\naKs5Egkb/ST1BbaG5U68aKhmqelP/ja/COJDkaYt/xy4humMqsXbAGipriMlcSJv\n/9GyRMLpO6n0HY+X1Si+WcdsimlXF+IRRw185jlUHniPS03YQg5lPhCd0TuIZzsg\no27gfCZ6LB1tQzv4jIxR8bXy8vJ4zHMn/jPCXKbu1SNBhAxrwttoGV8I2NaCMTrz\n/CGuGtwxH/2ddgGJzm6RJp1agbpzfXGvnSfUs5XD1QKBgQDzVw4rljy3uMM5oxth\n0kYixVkmjEwNhN92RuHXylIOEaU3JFhXjMkXnG/8QYPhysxy0v7u2Mf+PNBEJu5Z\nile1qwBVkaaFpQJf0H6JRSAR3XiV/C0tU3z98fPTMGVLWHZ+1bw00J/7etpPNnxq\n/34O0LmseyK0QTJpBSciG57HRwKBgQDolXAobDKc/vjVFIm0Arhh1O1iMILrCWe3\niLv9wlKD0XsqwGScc44qewKg6wLMAQt8vAKkgm8XLeaVqiV9U239Pd5XTsw5peMz\ngKQTXW+CCYeaee8MijA4WBFEC7zKA4pI1IUqxclAZSJIllm5QKigyksK1SPPfw1C\n04/cAfNkJQKBgQDeIKmewBXM36vWAT4NnZTISL2IoqkJ7jDOFwYxpU4h6ZmXLGY4\nBRvLodHXwzZP9n+KkXCFNv3Y5kp269LHibFm9CHp+nq3pFj2fawEQkV0CWb6Aqh6\np6uBZEvejF7q2GXSgei7jXV8aji99k68nVzEyWO0PvL++ES7+Sgvyma2IQKBgQCe\nuzZTcBxOxzide6nLH7wkkIP96iu/KqkNOhVKxy83ZWUQtUM0/yw8U71kg19iSShE\n1a9cBOS6RgpDhF6XnLPJDEtM/KsmgM+Bm77etHrgnY5dX/BEd58XJQuLjBMpL6dV\nsyy7F0cVWrA+J9ZONxGewtDaMMKC3yv2uppIxVANvQKBgQDE7Hb7V/Ue+yIVdgc/\no5Ie+kULalibNv5w1eci3MO279hLJZK78hdj863tqauy7NuxqTgoklGrkLxfnuE8\nr5dF5o+wCMGm2hqDnuhlfgu5lnChbDr/xVr12uHmfkuBLVJhkFoKL9bolNsI+a67\n5UYJuN9L3/tEBnl0L2n7HHI9qw==\n-----END PRIVATE KEY-----\n",
		// 			client_email: "marketing-site@marketing-site-280107.iam.gserviceaccount.com",
		// 			client_id: "107024417185529685930",
		// 			auth_uri: "https://accounts.google.com/o/oauth2/auth",
		// 			token_uri: "https://oauth2.googleapis.com/token",
		// 			auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
		// 			client_x509_cert_url:
		// 				"https://www.googleapis.com/robot/v1/metadata/x509/marketing-site%40marketing-site-280107.iam.gserviceaccount.com"
		// 		}
		// 	}
		// },
		{
			resolve: "gatsby-source-google-spreadsheet",
			options: {
				spreadsheetId: "1onJJ0XbkaUR_DHcv4XO4ONx6cIpCSrtUsGZU6oLJo0Q",
				// spreadsheetName: "Digital Resilience Training",
				credentials: {
					type: "service_account",
					project_id: "indigo-listener-254604",
					private_key_id: "bd3c0eb87ab7a86fd5299f4971c08a85c3fbb95e",
					private_key:
						"-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCsFZPp89Wy5akV\nUlpsWz+GV9SLy0VadHgyykELahfKBsw6jQfgLf4tbIh3070L5wjrA5pHGJb04FyG\nD9E2u9Woa2YEMAf9d96xEXnNnN0Uu5IL4OrI8Y6i4ynPKN2HJbqbiEEhkM63L2zl\nLCaT85UhD6qgbCXxSjlNdR0VaCbyLXL2uy7H7ZvHaFcs/zBjMeyiAvolx3aj73bt\nIHrNLA/e54gCG8rbTE4nSWH0Unia+JZxwkZmIkiskAIUUE3Kcv3BFnJGxotDYHpz\nfFEbrogpsW4wGpjVanLu54gGgEzMHBELTrP/8iDukjzkQ3klicxvg4XsU0wWj2hn\ndkmjRa9BAgMBAAECggEAAi9UE2oi+z537JU5EF+SV3A6msem9M2GNAoiDDklph0q\n6zaEoEyyMbFDTG7u81F0qS/4jcT4z/EwMIwvbT4SWe0K6JRD6jSbImE3BEikzJNO\n+O1TKs/pFk2rP4HwudtFbr2KquiG3N/ebnyWfO34lYjYeyjjXJBjSM3TE6040x3h\n8pOdsmDydP1j6oRyMh/+8WUr6K3tX4DJfDToS3Sni4YSs+BHzjFQjyUzRF7n4msA\nQevXqEKeKKCvO11ct3PwaghrgoJYgU8YBPYknFnveZAG9cIqC1vySD+ZPZEu2o2D\nn6k4l0HuGtdcICaUQoIzxDtZF/6tfN7MJf8p7uGXYQKBgQDkpDG7j8Oj5uPl+V0n\n9LV3AUTbC2ZYdojD6cmmig0UVkpoaMvNpE0qc00p31LxAXtH58acVU+vO5MeJ//D\nvYPoAOXQsuzydS687R71F/7LPbNd2wP4RgoBJzfM2Kfo5pOb4c8xxupNgihvSXwY\nMG65mqjor1mYY0EC15OpDXA/oQKBgQDArOk4dVpoWGWg42Bvr8Gh+UY+TJtYXYUB\n1UCeoyUir5oVPZ3PIdPTlIY7AI2UU0+9G6+XTjPG3s11RrVKlfKO49LSJs0LvDuy\nJMv/cKZ+kt4DK0apyFgka80AIUOzWA0ZlvTjnNSHIJ//4lemTqfKVMGYAh6ixisT\n3/3gZpnLoQKBgCcgTf5p0LHonhFJxf5DaxZNWkodvkrfJfJYvJBKSYi0Z5FMdavg\nNPBRwN+sftYNSdp5OEyicLts93UOpT8K3FrEZI99Pv0SvofIECcjDxAao+oow5ol\n6ipeWrcsUDeT1ai0UK+U+Z1/64l3ysuc5iXO6J7A+xUPzx08ZOFmElJhAoGAL1DH\nHSREhWdf3bi4z/SbWol6pIXviC2/IgoailqKHhB9iEXPYTRvboAEzqx+GDS1Wj1Y\nS5HayTWhwvEQSDa2kDyGYzFAHWemSUMcDai+Wkr61lBkVAFtE9SdTTDeDUbMQMJ/\ndP3oex6Z7B7LY33hWqChwhCTMWn03jadg+PuVMECgYAZXpNH8a31yEN7LjiLvvUv\nL/by8/qRsRt2a1ZjO+OEUJEsWzu/plOgeDR/CywQKDnIWG4BNLbibTPEMcJ7EP3a\nLvePRVWhCLJ2XqdzkAtixo4f00yVuGzNDusCeo+/iAebxdGG0ADtLbxbeiPTTeWP\nGXS8ORPV90h9w0Wxwv4XHw==\n-----END PRIVATE KEY-----\n",
					client_email: "sercer@indigo-listener-254604.iam.gserviceaccount.com",
					client_id: "104267935825426305893",
					auth_uri: "https://accounts.google.com/o/oauth2/auth",
					token_uri: "https://oauth2.googleapis.com/token",
					auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
					client_x509_cert_url:
						"https://www.googleapis.com/robot/v1/metadata/x509/sercer%40indigo-listener-254604.iam.gserviceaccount.com"
				}
			}
		},
		{
			resolve: "gatsby-source-google-spreadsheet",
			options: {
				spreadsheetId: "1onJJ0XbkaUR_DHcv4XO4ONx6cIpCSrtUsGZU6oLJo0Q",
				// spreadsheetName: "New EAP Pricing",
				credentials: {
					type: "service_account",
					project_id: "indigo-listener-254604",
					private_key_id: "bd3c0eb87ab7a86fd5299f4971c08a85c3fbb95e",
					private_key:
						"-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCsFZPp89Wy5akV\nUlpsWz+GV9SLy0VadHgyykELahfKBsw6jQfgLf4tbIh3070L5wjrA5pHGJb04FyG\nD9E2u9Woa2YEMAf9d96xEXnNnN0Uu5IL4OrI8Y6i4ynPKN2HJbqbiEEhkM63L2zl\nLCaT85UhD6qgbCXxSjlNdR0VaCbyLXL2uy7H7ZvHaFcs/zBjMeyiAvolx3aj73bt\nIHrNLA/e54gCG8rbTE4nSWH0Unia+JZxwkZmIkiskAIUUE3Kcv3BFnJGxotDYHpz\nfFEbrogpsW4wGpjVanLu54gGgEzMHBELTrP/8iDukjzkQ3klicxvg4XsU0wWj2hn\ndkmjRa9BAgMBAAECggEAAi9UE2oi+z537JU5EF+SV3A6msem9M2GNAoiDDklph0q\n6zaEoEyyMbFDTG7u81F0qS/4jcT4z/EwMIwvbT4SWe0K6JRD6jSbImE3BEikzJNO\n+O1TKs/pFk2rP4HwudtFbr2KquiG3N/ebnyWfO34lYjYeyjjXJBjSM3TE6040x3h\n8pOdsmDydP1j6oRyMh/+8WUr6K3tX4DJfDToS3Sni4YSs+BHzjFQjyUzRF7n4msA\nQevXqEKeKKCvO11ct3PwaghrgoJYgU8YBPYknFnveZAG9cIqC1vySD+ZPZEu2o2D\nn6k4l0HuGtdcICaUQoIzxDtZF/6tfN7MJf8p7uGXYQKBgQDkpDG7j8Oj5uPl+V0n\n9LV3AUTbC2ZYdojD6cmmig0UVkpoaMvNpE0qc00p31LxAXtH58acVU+vO5MeJ//D\nvYPoAOXQsuzydS687R71F/7LPbNd2wP4RgoBJzfM2Kfo5pOb4c8xxupNgihvSXwY\nMG65mqjor1mYY0EC15OpDXA/oQKBgQDArOk4dVpoWGWg42Bvr8Gh+UY+TJtYXYUB\n1UCeoyUir5oVPZ3PIdPTlIY7AI2UU0+9G6+XTjPG3s11RrVKlfKO49LSJs0LvDuy\nJMv/cKZ+kt4DK0apyFgka80AIUOzWA0ZlvTjnNSHIJ//4lemTqfKVMGYAh6ixisT\n3/3gZpnLoQKBgCcgTf5p0LHonhFJxf5DaxZNWkodvkrfJfJYvJBKSYi0Z5FMdavg\nNPBRwN+sftYNSdp5OEyicLts93UOpT8K3FrEZI99Pv0SvofIECcjDxAao+oow5ol\n6ipeWrcsUDeT1ai0UK+U+Z1/64l3ysuc5iXO6J7A+xUPzx08ZOFmElJhAoGAL1DH\nHSREhWdf3bi4z/SbWol6pIXviC2/IgoailqKHhB9iEXPYTRvboAEzqx+GDS1Wj1Y\nS5HayTWhwvEQSDa2kDyGYzFAHWemSUMcDai+Wkr61lBkVAFtE9SdTTDeDUbMQMJ/\ndP3oex6Z7B7LY33hWqChwhCTMWn03jadg+PuVMECgYAZXpNH8a31yEN7LjiLvvUv\nL/by8/qRsRt2a1ZjO+OEUJEsWzu/plOgeDR/CywQKDnIWG4BNLbibTPEMcJ7EP3a\nLvePRVWhCLJ2XqdzkAtixo4f00yVuGzNDusCeo+/iAebxdGG0ADtLbxbeiPTTeWP\nGXS8ORPV90h9w0Wxwv4XHw==\n-----END PRIVATE KEY-----\n",
					client_email: "sercer@indigo-listener-254604.iam.gserviceaccount.com",
					client_id: "104267935825426305893",
					auth_uri: "https://accounts.google.com/o/oauth2/auth",
					token_uri: "https://oauth2.googleapis.com/token",
					auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
					client_x509_cert_url:
						"https://www.googleapis.com/robot/v1/metadata/x509/sercer%40indigo-listener-254604.iam.gserviceaccount.com"
				}
			}
		},
		{
			resolve: "gatsby-source-google-spreadsheet",
			options: {
				// spreadsheetId: "1bPmpuXyT-S0p26WouzE3qgvyeQYPV3VMptjtPGanJXQ",
				spreadsheetId: "1gxzDGflxUs7PMr025UZThq5j25CkUkbR7KtP3APYmpQ",
				// worksheetTitle: "Fixed Fee",
				// spreadsheetName: "Sheet1",
				credentials: {
					type: "service_account",
					project_id: "marketing-site-280107",
					private_key_id: "6cfd02187077dd0a1fdd7f5fbe680a29e74578be",
					private_key:
						"-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDdFPEfQiuWlvrB\n230SNePYKRB78MKnKqiI1aPAEeO0EH1eBYRok/YYAhi1K8BtXM+F/iP8dPeRF3bu\nFGqZgVnqJFHVa6R+eAHbz5Z5Cx2MLsI9RW7UFIVJxJcBpwAYpfhqMx+oZmLwX5G9\nYlVb6O9Nq1uDE7I1f4eNXfAcopmqUXiRBNnFYoz6a2e6OPtNQFiX7MjfplCkhrfI\nid7ie7GsDirq1N33oG88SXyZCf+RDeSUBd3neabncTYC8cQZAJf1zN8bmi+UiW4w\np6GaGxB3UfsLJ0uoOzMh5OukXkhO0jABCKvlToF0dQqFBFJgS/Ht50crs6KL8XPM\nuzpzL4lDAgMBAAECggEABEgT5xaSnj8OuAVmiAr32d5YAk+dRpeoQlfc2qHnNDwI\nFzyy1LGnKhcmlusPfy61UU7t5sVjtSgjP7dDE9D7RpbIeqbYhp9YyUvvigRvctfq\naKs5Egkb/ST1BbaG5U68aKhmqelP/ja/COJDkaYt/xy4humMqsXbAGipriMlcSJv\n/9GyRMLpO6n0HY+X1Si+WcdsimlXF+IRRw185jlUHniPS03YQg5lPhCd0TuIZzsg\no27gfCZ6LB1tQzv4jIxR8bXy8vJ4zHMn/jPCXKbu1SNBhAxrwttoGV8I2NaCMTrz\n/CGuGtwxH/2ddgGJzm6RJp1agbpzfXGvnSfUs5XD1QKBgQDzVw4rljy3uMM5oxth\n0kYixVkmjEwNhN92RuHXylIOEaU3JFhXjMkXnG/8QYPhysxy0v7u2Mf+PNBEJu5Z\nile1qwBVkaaFpQJf0H6JRSAR3XiV/C0tU3z98fPTMGVLWHZ+1bw00J/7etpPNnxq\n/34O0LmseyK0QTJpBSciG57HRwKBgQDolXAobDKc/vjVFIm0Arhh1O1iMILrCWe3\niLv9wlKD0XsqwGScc44qewKg6wLMAQt8vAKkgm8XLeaVqiV9U239Pd5XTsw5peMz\ngKQTXW+CCYeaee8MijA4WBFEC7zKA4pI1IUqxclAZSJIllm5QKigyksK1SPPfw1C\n04/cAfNkJQKBgQDeIKmewBXM36vWAT4NnZTISL2IoqkJ7jDOFwYxpU4h6ZmXLGY4\nBRvLodHXwzZP9n+KkXCFNv3Y5kp269LHibFm9CHp+nq3pFj2fawEQkV0CWb6Aqh6\np6uBZEvejF7q2GXSgei7jXV8aji99k68nVzEyWO0PvL++ES7+Sgvyma2IQKBgQCe\nuzZTcBxOxzide6nLH7wkkIP96iu/KqkNOhVKxy83ZWUQtUM0/yw8U71kg19iSShE\n1a9cBOS6RgpDhF6XnLPJDEtM/KsmgM+Bm77etHrgnY5dX/BEd58XJQuLjBMpL6dV\nsyy7F0cVWrA+J9ZONxGewtDaMMKC3yv2uppIxVANvQKBgQDE7Hb7V/Ue+yIVdgc/\no5Ie+kULalibNv5w1eci3MO279hLJZK78hdj863tqauy7NuxqTgoklGrkLxfnuE8\nr5dF5o+wCMGm2hqDnuhlfgu5lnChbDr/xVr12uHmfkuBLVJhkFoKL9bolNsI+a67\n5UYJuN9L3/tEBnl0L2n7HHI9qw==\n-----END PRIVATE KEY-----\n",
					client_email: "marketing-site@marketing-site-280107.iam.gserviceaccount.com",
					client_id: "107024417185529685930",
					auth_uri: "https://accounts.google.com/o/oauth2/auth",
					token_uri: "https://oauth2.googleapis.com/token",
					auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
					client_x509_cert_url:
						"https://www.googleapis.com/robot/v1/metadata/x509/marketing-site%40marketing-site-280107.iam.gserviceaccount.com"
				}
			}
		},
		{
			resolve: `gatsby-plugin-canonical-urls`,
			options: {
				siteUrl: `https://uprise.co`
			}
		},
		{
			resolve: "gatsby-plugin-root-import",
			options: {
				src: join(__dirname, "src"),
				"@styles": join(__dirname, "src/styles"),
				"@constants": join(__dirname, "src/constants"),
				"@images": join(__dirname, "src/images"),
				"@components": join(__dirname, "src/components"),
				"@validation": join(__dirname, "src/validation"),
				"@utils": join(__dirname, "src/utils"),
				"@fonts": join(__dirname, "src/fonts")
			}
		},
		{
			resolve: `gatsby-plugin-styled-components`
		}
		// {
		// 	resolve: `gatsby-plugin-google-fonts`,
		// 	options: {
		// 		fonts: [
		// 			`Proxima Nova`,
		// 			`600`
		// 		],
		// 		display: 'swap'
		// 	}
		// },
	]
}
